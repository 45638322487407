(function () {
  'use strict';

  var headerImageMatch = (location.search || '').match(/h=([-\w]+)/);
  var headerImage = document.querySelector('#qualified-hero-image');
  if (headerImageMatch && headerImage) {
    headerImage.src = headerImage.src.replace(/(hero-examples\/)([-\w]+)\.png$/, '$1' + headerImageMatch[1] + '.png');
  }

}());
